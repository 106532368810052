// Include all widget styling
// File/folders should match the structure of spadmin/src/widgets
.widget {
    padding: 0px 30px 0px 30px;
    margin: 0px 0px 30px 0px;
    width: 100%;
    @include flex;
    @include breakpoint(small) {
        padding: 0px 15px 0px 15px;
    }
    &.half {
        width: 50%;
    }
    &.bordered {
        .widget-inner {
            @include standard-border;
            padding: 30px 30px 30px 30px;
            @include breakpoint(small) {
                border: 0px;
                padding: 0px;
            }
        }
    }
    &.no-margin {
        margin: 0px 0px 0px 0px;
    }
    &.no-padding {
        padding: 0px 0px 0px 0px;
    }
    &.spacer {
        padding-bottom: 30px;
        border-bottom: 15px solid $gray;
    }
    .widget-inner {
        width: 100%;

        @import 'accordion';
        @import 'action-header';
        @import 'alert';
        @import 'filters';
        @import 'headline';
        @import 'table-widget';
        @import 'timeline';

    }
}

/* Table Styles are in the table-widget */
// Are these necessary?
@import 'table-widget';
@import 'filters';
@import 'headline';
@import 'container';
