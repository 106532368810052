// These styles will work everywhere, including in body and modal content
*::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
*::-webkit-scrollbar-track {
    background: $light-gray;
}
*::-webkit-scrollbar-thumb {
    background: $dark-gray;
    border-radius: 0px;
    transition: all 5s;
    -webkit-transition: all 5s;
}

body {
    background: $body-background;
    @include proxima-nova(400);
}

/* Layout-specific elements in SPAdmin */
#wrapper {
    overflow-x: hidden;
    width: 100%;
    @include proxima-nova(400);
    @include text-rem(14);
    background: $wrapper-background;
}
#wrapper.nav-hidden #main{
    @include breakpoint(large) {
        margin-left: 0px;
    }
}
#main {
    margin-left: 252px;
    transition: margin 0.35s;
    min-width: 320px;
    min-height: 100vh;
    @include flex-wrap;
    flex-direction: column;
    &.transition {
        opacity: 0;
        transition: opacity 0s;
    }
    transition: opacity 0.35s;
}
#content-block {
    width: 100%;
    flex-grow: 1;
    @include flex;
    @include breakpoint(medium) {
        @include flex-wrap;
        flex-direction: column;
    }
    #content {
        background: white;
        padding: 30px 0px 70px 0px;
        width: 100%;
        min-width: 400px;
        @include breakpoint(medium) {
            flex-grow: 1;
        }
        @include breakpoint(small) {
            padding: 30px 0px 70px 0px;
        }
    }
}

h2 {
    color: $primary-bold;
    @include text-rem(18);
    margin-bottom: 20px;
    @include proxima-nova(400);
}

h3 {
    color: $primary-bold;
    @include text-rem(18);
    margin-bottom: 20px;
    @include proxima-nova(400);
    .subtext {
        margin-top: 6px;
        display: block;
        color: $text-gray;
        @include text-rem(12);
    }
}

p {
    color: $copy-color;
    margin-bottom: 20px;
    &.mice {
        @include text-rem(12);
    }
    &.big-number {
        @include text-rem(40);
        @include proxima-nova(300);
        color: $primary-bold;
        .sub-number {
            @include text-rem(24);
        }
    }
}

a {
    color: $link-color;
    &:hover {
        text-decoration: underline;
    }
    &.button {
        transition: color 0.35s, background 0.35s, opacity 0.35s;
        color: $button-color;
        text-decoration: none;
    }
    &.button.primary {
        background: $primary-gradient;
        color: white;
        &:hover {
            background: $primary-gradient-reverse;
        }
    }
    &.button.light-gray {
        background: $tag-gray;
        color: white;
        &:hover {
            background: $tag-gray;
        }
    }
    &.button.red {
        background: $bright-red;
        color: white;
        &:hover {
            background: $bright-red;
        }
    }
    &.button.orange {
        background: $blood-orange;
        color: white;
        &:hover {
            background: $blood-orange;
        }
    }
    &.button.gold {
        background: $gold;
        color: white;
        &:hover {
            background: $gold;
        }
    }
    &.button.green {
        background: $green;
        color: white;
        &:hover {
            background: $green;
        }
    }
    &.button.create {
        background: $button-create-background-color;
        color: white;
        &:hover {
            background: $button-create-background-color;
        }
    }
}

i.fa {
    &.interactive {
        cursor: pointer;
    }
    &.green {
        color: $green;
    }
    &.red {
        color: $bright-red;
    }
}

.bold {
    @include proxima-nova(600);
    color: $primary-bold;
    font-weight: bold;
}
.heavy {
    @include proxima-nova(900);
    color: black;
    text-transform: uppercase;
}

.center {
    text-align: center;
}

.span-uppercase {
    span {
        text-transform: uppercase;
    }
}

.action-buttons {
    @include flex-wrap;
    justify-content: flex-start;

    &.right {
        justify-content: flex-end;
    }

    &.center {
        justify-content: center;
    }
}

.actions-dropdown {
    position: relative;
    .actions-dropdown-current {
        @include standard-border;
        background-image: linear-gradient(to top, #f2f4f7 0%, #ffffff 100%);
        padding: 10px 28px 10px 14px;
        position: relative;
        cursor: pointer;
        z-index: 5;
        .actions-dropdown-current-label {
            color: $primary-bold;
            @include proxima-nova(600);
            @include text-rem(14);
        }
        i {
            color: $solid-gray;
            position: absolute;
            bottom: 10px;
            right: 10px;
        }
    }
    ul {
        display: none;
        @include standard-border;
        border-radius: 0px 0px 4px 4px;
        border-top: 0px;
        background: white;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08);
        text-align: left;
        margin-top: -2px;
        position: absolute;
        z-index: 6;
        width: 100%;
        top: 36px;
        left: 0px;
        li {
            color: $primary-bold;
            @include proxima-nova(600);
            @include text-rem(14);
            padding: 10px 18px 10px 18px;
            border-bottom: 1px solid $default-border-color;
            cursor: pointer;
            a {
                color: $primary-bold;
                text-decoration: none;
                display: block;
            }
            &.active {
                background: $primary-bold-active;
            }
            &:hover {
                background: $primary-bold-active;
            }
        }
        &.open {
            display: block;
        }
    }
}

.button {
    @include standard-border;
    background-image: linear-gradient(to top, #f2f4f7 0%, #ffffff 100%);
    padding: 10px 16px 10px 14px;
    cursor: pointer;
    z-index: 5;
    color: $primary-bold;
    margin-right: 12px;
    @include proxima-nova(600);
    @include text-rem(14);

    @include button-styles;

    &:hover {
        text-decoration: none;
        background-image: linear-gradient(to top, #ffffff 0%, #f2f4f7 100%);
    }
    &:focus {
        outline:none;
        border:1px solid $button-focus-border-color;
        -webkit-box-shadow: 0px 0px 5px  $button-focus-border-color;
        box-shadow: 0px 0px 5px  $button-focus-border-color;
    }
    &:disabled, &.disabled {
        opacity: 0.6;
        background: $button-disabled-background-color;
        color: $button-disabled-color;
        &:hover {
            opacity: 0.6;
            background: $button-disabled-background-color;
            color: $button-disabled-color;
        }
    }
}

.buttons {
    @include flex-wrap;
    width: 100%;
    .button {
        margin-bottom: 20px;
        max-height: 36px;
        @include button-styles;
    }
}

.columns {
    @include flex-wrap;
    margin: 0px -14px 0px -14px;
}

.column {
    padding: 0px 14px 22px 14px;
    width: 100%;
    &.auto {
        width: auto;
    }
    &.half {
        width: 50%;
        @include breakpoint(small) {
            width: 100%;
        }
    }
    &.third {
        width: 33.332%;
        @include breakpoint(small) {
            width: 100%;
        }
    }
    &.twothird {
        width: 66.665%;
        @include breakpoint(small) {
            width: 100%;
        }
    }
    &.quarter {
        width: 25%;
        @include breakpoint(small) {
            width: 100%;
        }
    }
    &.right {
        margin-left: auto;
    }
    &:last-of-type {
        padding: 0px 14px 0px 14px;
    }
    &.numeric {
        input {
            text-align: right;
            @include droid;
        }
    }
    &.small-padding-bottom {
        padding-bottom: 8px;
    }
    &.header {
        border-bottom: 1px solid $default-border-color;
        border-top: 1px solid $default-border-color;
        padding: 8px;
        margin-bottom: 22px;
        h5 {
            color: $primary-bold;
            @include text-rem(16);
            @include proxima-nova(600);
        }
    }
    &.hidden {
        display: none;
    }
    &.subselect {
        margin-left: 1.5rem;
    }

    &.field-group {
        padding-left: 0;
        padding-right: 0;
        > div {
            @include flex-wrap;
        }
        &.boxy {
            @include standard-border;
            padding: 20px 0px 0px 0px;
            margin-bottom: 20px;
            margin: 0px 14px 20px 14px;
            background-color: $eggshell;
            .column:last-of-type {
                padding: 0px 14px 22px 14px;
            }
        }
    }

    &.no-padding {
        padding: 0;
    }
}

.picker {
    @include proxima-nova(400);
    @include text-rem(14);
}

label {
    color: $copy-color;
    @include proxima-nova(600);
    @include text-rem(14);
    margin-bottom: 7px;
    display: block;
    min-height: 14px;
}

input {
    -webkit-appearance: none;
    outline: none;
}

input[type='text'], input[type='password'], input[type='tel'] {
    @include standard-border;
    background: white;
    @include proxima-nova(600);
    color: $primary-bold;
    @include text-rem(14);
    padding: 8px 15px 9px 15px;
    display: block;
    width: 100%;
    &:read-only {
        &:not(.picker__input):not(.fp-ainput) {
            opacity: 0.6;
        }
    }
}

input[type='range'] {
    vertical-align: middle;
    -webkit-appearance: none;
    width: 100%;
    background: transparent;
    margin: 0px;
    @mixin rangeThumb {
        @include standard-border;
        border: 1px solid #27aa11;
        height: 18px;
        width: 18px;
        background: $green-gradient;
        cursor: pointer;
        border-radius: 12px;
        &:after {
            content: " ";
            background: white;
            height: 12px;
            width: 12px;
        }
    }
    @mixin rangeTrack {
        width: 100%;
        height: 20px;
        cursor: pointer;
        background-image: linear-gradient(to top, #ffffff 0%, #f2f4f7 100%);
        @include standard-border;
        border-radius: 12px;
    }
    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        @include rangeThumb;
        margin-top: 0px;
    }
    input[type=range]::-moz-range-thumb {
        @include rangeThumb;
    }
    &:focus {
        outline:none;
    }
    &::-webkit-slider-runnable-track {
        @include rangeTrack;
    }
    :focus::-webkit-slider-runnable-track {
        @include rangeTrack;
    }
    &::-moz-range-track {
      @include rangeTrack;
    }
    & + label {
        display: block;
        margin-top: 10px;

    }
}
input, textarea, select {
    &.error {
        border: 1px solid $bright-red;
    }
    &:disabled {
        opacity: 0.6;
        background: $disabled;
    }
}
span.checkmark, span.radio {
    &.error {
        border: 1px solid $bright-red;
    }
}
.select {
    position: relative;
    i {
        position: absolute;
        top: 11px;
        right: 16px;
        color: $solid-gray;
        pointer-events: none;
        @include text-rem(14);
    }
}
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    //outline: none;
    @include standard-border;
    background-image: linear-gradient(to top, #f2f4f7 0%, #ffffff 100%);
    padding: 8px 36px 9px 14px;
    color: $charcoal;
    @include proxima-nova(600);
    @include text-rem(14);
    width: 100%;
    &:focus {
        outline:none;
        border:1px solid $select-focus-border-color;
        -webkit-box-shadow: 0px 0px 5px  $select-focus-border-color;
        box-shadow: 0px 0px 5px  $select-focus-border-color;
    }
}
textarea {
    width: 100%;
    -webkit-appearance: none;
    outline: none;
    height: 153px;
    @include standard-border;
    background: white;
    @include proxima-nova(600);
    color: $primary-bold;
    @include text-rem(14);
    padding: 15px 15px 15px 15px;
    display: block;
    resize: none;
}
.message {
    text-align: center;
    padding-top: 10px;

    &.left {
        text-align: left;
    }
}
.checkbox {
    position: relative;
    cursor: pointer;
    /* Hide the browser's default checkbox */
    input {
        position: absolute;
        opacity: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        display: inline-block;
        height: 16px;
        width: 16px;
        @include standard-border;
        vertical-align: middle;
        position: relative;
        margin-right: 9px;
        background-image: linear-gradient(to top, #f2f4f7 0%, #ffffff 100%);
        vertical-align: bottom;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    input:checked ~ .checkmark {
        border: 1px solid $border-green;
        background-image: $green-gradient;
    }

    input:checked ~ .text {
        color: $primary-bold;
    }

    input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .checkmark:after {
        left: 5px;
        top: 1px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}
.radios {
    position: relative;
    cursor: pointer;
    /* Hide the browser's default checkbox */
    input {
        position: absolute;
        opacity: 0;
    }
    /* create custom radio */
    .radio {
        display: inline-block;
        height: 16px;
        width: 16px;
        @include standard-border;
        border-radius: 50%;
        vertical-align: middle;
        position: relative;
        margin-right: 9px;
        background-image: linear-gradient(to top, #f2f4f7 0%, #ffffff 100%);
        vertical-align: bottom;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .radio:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    input:checked ~ .radio {
        border: 1px solid $border-green;
        background-image: $green-gradient;
    }

    input:checked ~ .text {
        color: $primary-bold;
    }

    input:checked ~ .radio:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .radio:after {
        left: 4px;
        top: 4px;
        width: 6px;
        height: 6px;
        background: white;
        border-radius: 50%;
    }
}
.file {
    position: relative;
    cursor: pointer;
    min-height: 33px;
    input {
        width: 100%;
        margin: 0px;
        filter: alpha(opacity=0);
        opacity: 0;
        min-height: 33px;
        cursor: pointer;
        &.error ~ .file-custom {
            border: 1px solid $bright-red;
        }
    }
    .file-custom {
        position: absolute;
        top: 0px;
        right: 0;
        left: 0;
        z-index: 5;
        @include standard-border;
        background: white;
        @include proxima-nova(600);
        color: $primary-bold;
        @include text-rem(14);
        padding: 8px 15px 9px 15px;
        display: block;
        width: 100%;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        pointer-events: none;
        cursor: pointer;
        min-height: 33px;
        &.read-only {
                opacity: 0.6;
        }
        &:before {
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            z-index: 6;
            display: block;
            content: "Browse";
            height: 31px;
            padding: 8px 15px 9px 15px;
            color: #fff;
            background-color: $primary;
            border-radius: 4px;
            pointer-events: none;
            cursor: pointer;
        }
        span {
            pointer-events: none;
            cursor: pointer;
        }
    }
}
.boxy {
    .radios-wrapper, .checkboxes-wrapper {
        .radios, .checkbox {
            @include standard-border;
            border-radius: 0px;
            border-bottom: 0px;
            margin-bottom: 0px;
            background-image: linear-gradient(to top, #f2f4f7 0%, #ffffff 100%);
            padding: 10px 16px 10px 14px;
            cursor: pointer;
            &:first-of-type {
                border-radius: 4px 4px 0px 0px;
            }
            &:last-of-type {
                border-bottom: 1px solid #dfe2e5;
                border-radius: 0px 0px 4px 4px;
            }
        }
    }
}
.vertical {
    &.boxy {
        .radios-wrapper, .checkboxes-wrapper {
            min-height: 36px;
            .radios, .checkbox {
                border-bottom: 1px solid #dfe2e5;
                padding: 9px 16px 9px 14px;
                &:first-of-type {
                    border-radius: 4px 0px 0px 4px;
                    border-bottom: 1px solid #dfe2e5;
                }
                &:last-of-type {
                    border-bottom: 1px solid #dfe2e5;
                    border-right: 1px solid #dfe2e5;
                    border-radius: 0px 4px 4px 0px;
                }
            }
        }
    }
    .radios-wrapper, .checkboxes-wrapper {
        @include flex;
        .radios, .checkbox {
            border-right: 0px;
            flex-grow: 1;
        }
    }
}

// Tag styles. Ensure new tag classes are present in pump/spadmin/widgets/forms/CellTag::CLASSES constant
.tag {
    border-radius: 3px;
    background: $copy-color;
    color: white;
    @include proxima-nova(700);
    @include text-rem(10);
    text-transform: uppercase;
    display: inline-block;
    padding: 4px 6px 4px 8px;
    margin-right: 3px;
    margin-bottom: 4px;
    &.create {
        padding: 4px 6px 4px 6px
    }
    a.remove {
        margin-left: 4px;
        color: black;
        opacity: 0.25;
        text-decoration: none;
    }
    a.add {
        color: white;
        opacity: 1;
        text-decoration: none;
    }
    &.large {
        @include text-rem(11);
        padding: 5px 7px 5px 9px;
    }
    &.light-gray {
        background-color: $tag-gray;
    }
    &.red {
        background-color: $bright-red;
    }
    &.orange {
        background-color: $blood-orange;
    }
    &.gold {
        background-color: $gold;
    }
    &.green {
        background-color: $green;
    }
    &.create {
        background-color: $tag-create-background-color;
        color: white;
    }
    &.wide {
        width: 100%;
    }
    &.primary {
        background-color: $primary;
    }
}

.timer {
    .timer-inner {
        width: 20px;
        height: 20px;
        margin: 0px 0px 4px 0px;
        position: relative;
        .timer-background {
            position: absolute;
            width: 20px;
            height: 20px;
            border-radius: 9px;
            margin: -1px 0px 0px -1px;
        }
        .time-remaining-under-fifty-percent {
            transform: rotate(180deg);
            position: absolute;
            width: 18px;
            height: 18px;
            border-radius: 9px;
            clip: rect(0px, 18px, 18px, 9px);
            .piece {
                transition: all 1s;
                position: absolute;
                width: 18px;
                height: 18px;
                border-radius: 9px;
                clip: rect(0px, 9px, 18px, 0px);
            }
        }
        .time-remaining-over-fifty-percent {
            transform: rotate(0deg);
            position: absolute;
            width: 18px;
            height: 18px;
            border-radius: 9px;
            clip: rect(0px, 18px, 18px, 9px);
            .piece {
                transition: all 1s;
                position: absolute;
                width: 18px;
                height: 18px;
                border-radius: 9px;
                clip: rect(0px, 9px, 18px, 0px);
                background-color: rgba(0,0,0,0.5);
            }
        }
    }
}

.key-value-table {
     @include proxima-nova(400);
     @include text-rem(14);

     display: table;
     table-layout: fixed;
     border-collapse: collapse;

     margin-bottom: 1.25rem;

     > div {
        display: table-row;

        &.spaced {
            > div {
                padding-top: 0.75rem;
            }
        }

        &.border-top {
            border-top: 3px double #000;
        }

        > div {
            display: table-cell;
            vertical-align: top;
            padding: 0.25rem;
            padding-left: 0.75rem;

            &:first-child {
                font-weight: bold;
                padding-left: 0.25rem;
            }

            &.bold {
                font-weight: bold;
            }

            &.money {
                @include droid;
                text-align: right;
            }
            &.numeric {
                @include droid;
                text-align: right;
                &.left {
                    text-align: left;
                }
            }
        }
     }
}
