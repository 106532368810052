#modal, .filter-modal {
    @include flex-wrap;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.35);
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 99998;
    overflow-y: auto;
    display: none;
    opacity: 0;
    &.open {
        display: inline-flex;
        align-items: center;
    }

    h4 {
        color: $primary-bold;
        @include proxima-nova(600);
        @include text-rem(14);
        line-height: 19px;
        text-align: center;
        margin-bottom: 8px;
        width: 100%;
    }

    #modal-box, .modal-box {
        padding: 14px 3px 0px 3px;
        @include standard-border;
        background: $modal-background;
        width: 100%;
        max-width: 580px;
        margin: auto auto auto auto;
        position: relative;
        &.max-size {
            max-width: calc(100% - 40px);
            max-height: calc(100% - 40px);
            overflow: auto;
        }
        #modal-close, .modal-close {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
            color: $copy-color;
            @include proxima-nova(600);
            @include text-rem(14);
        }
    }
    form {
        h4 {
            color: $primary-bold;
            @include proxima-nova(600);
            @include text-rem(14);
            line-height: 19px;
            text-align: center;
            margin-bottom: 8px;
            width: 100%;
        }
        h3 {
            color: $primary-bold;
            @include text-rem(18);
            margin-bottom: 20px;
            @include proxima-nova(400);
            .subtext {
                margin-top: 6px;
                display: block;
                color: $copy-color;
                @include text-rem(12);
            }
        }
        p {
            color: black;
            @include proxima-nova(400);
            @include text-rem(14);
        }
    }
}
