.container-widget {
    &.well {
        padding-top: 1rem;
        background-color: #fafafa;
        border: 1px solid #dfe2e5;
        border-radius: 0.5rem;
        margin-bottom: 1.5rem;
    }

    .container-widget-inner {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-wrap: wrap;
        flex-wrap: -webkit-wrap;
    }
}
