.headline-widget {
    background: $gray;
    padding: 0px 30px 0px 30px;
    @include breakpoint(small) {
        padding: 0px 15px 0px 15px;
    }
    .headline-widget-inner {
        padding: 27px 0px 5px 0px;
        h2 {
            @include text-rem(20);
        }
    }
}
