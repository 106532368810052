/***************************
    Colour Variables
***************************/
$slate-blue: #30404e !default;
$pale-blue: #ced0d3 !default;
$dark-red: #922f2a !default;
$bright-red: #c90000 !default;
$cherry: #f03131 !default;
$rose: #ff5559 !default;
$blood-orange: #d55742 !default;
$eggshell: #fdfdfd !default;
$gray: #ebedef !default;
$trans-gray: rgba(168,168,168,0.2) !default;
$trans-white: rgba(255,255,255,0.1) !default;
$trans-white-subtle: rgba(255,255,255,0.05) !default;
$light-gray: #f9f9f9 !default;
$medium-gray: #6c7c93 !default;
$extra-gray: #e5e5e5 !default;
$darker-gray: #e0e0e0 !default;
$dark-gray: #979797 !default;
$blue-gray: #ecf0f6 !default;
$off-gray: #d8dbdf !default;
$off-white: #d7d9db !default;
$gray-blue: #767d85 !default;
$charcoal: #354052 !default;
$text-gray: #7f8fa4 !default;
$light-blue: #2f6de1 !default;
$baby-blue: #4a90e9 !default;
$baby-blue-alt: #4D90FE !default;
$solid-gray: #a8a8a8 !default;
$tag-gray: #c2cad4 !default;
$almost-white: #fafbfc !default;
$gold: #f5a623 !default;
$green-gradient: linear-gradient(to top, #29b311 0%, #57d841 100%) !default;
$green-gradient-reverse: linear-gradient(to bottom, #29b311 0%, #57d841 100%) !default;
$green: #29b311 !default;
$border-green: #27aa11 !default;
$mint: #ddffe2 !default;

/***************************
    Theming Variables
***************************/
$primary: #5cb739 !default;
$primary-light: #71b756 !default;
$primary-bold: $charcoal !default;
$primary-bold-active: $gray !default;
$primary-gradient: linear-gradient(180deg, $primary-light 0%, $primary 100%) !default;
$primary-gradient-reverse: linear-gradient(0deg, $primary-light 0%, $primary 100%) !default;

/* Theming Colours - Specific Elements */
$body-background: $light-gray !default;
$wrapper-background: $gray !default;

/* Nav Theming */
$nav-background-color: $slate-blue !default;
$nav-link-color: $pale-blue !default;
$nav-link-active-color: $trans-gray !default;
$nav-link-notification-background-color: $slate-blue !default;
$nav-logo-background: $gray !default;
$nav-bottom-separator-color: $trans-white !default;
$nav-bottom-hover-background: $trans-white-subtle !default;
$nav-bottom-user-icon-color: $dark-gray !default;
$nav-bottom-username-color: $off-white !default;
$nav-bottom-user-role-color: $gray-blue !default;
$nav-bottom-expand-color: $nav-bottom-user-icon-color !default;
$nav-bottom-item-color: $off-white !default;
$nav-bottom-item-color-hover: $trans-white-subtle !default;

/* Content Nav (Subnav) Theming */
$toggle-content-nav-background: $light-gray !default;
$toggle-content-nav-border-color: $off-gray !default;
$toggle-content-nav-copy-color: $dark-gray !default;

$content-nav-background: $light-gray !default;
$content-nav-medium-border-color: $off-gray !default;
$content-nav-copy-color: #000 !default;
$content-nav-hover-background: $darker-gray !default;

/* Top Bar: shown on smallest responsive layout (mobile) */
$top-bar-background: $extra-gray !default;
$top-bar-border-color: $off-gray !default;
$top-bar-button-color: $dark-gray !default;
$top-bar-button-border-color: $top-bar-border-color !default;

/* Header */
$header-background: $gray !default;
$header-copy-color: $primary !default;

$modal-background: $light-gray !default;

$copy-color: $text-gray !default;
$link-color: $light-blue !default;
$default-border-color: #dfe2e5 !default;
$disabled: #eeeeee !default;

$button-color: $primary-bold !default;
$button-create-background-color: $off-gray !default;
$button-focus-border-color: $baby-blue-alt !default;
$button-disabled-background-color: $disabled !default;
$button-disabled-color: $text-gray !default;
$button-primary-background: $primary-gradient !default;
$button-primary-hover-background: $primary-gradient-reverse !default;

$select-focus-border-color: $button-focus-border-color !default;

$tag-create-background-color: $button-create-background-color !default;

$table-header-gray: #868686 !default;
$table-subtext-gray: #8e9cad !default;

/* Gridberg Variables */
$total-columns: 12 !default;
/* Set your breakpoints in px, add as many as you like. */
$breakpoints: (
    large: 1000px,
    medium: 767px,
    smedium: 700px,
    small: 600px
) !default;
/* Set the default padding for your columns */
$column-padding-right: 10px !default;
$column-padding-left: 10px !default;
