.filter {
    &:first-child {
        margin-left: 30px;
    }
}

.filters-label {
    opacity: 0.57;
    color: $table-header-gray;
    @include proxima-nova(600);
    @include text-rem(12);
    text-transform: uppercase;
    padding: 12px 7px 0px 15px;
}

.filter-wrapper {
    padding: 0px 6px 30px 6px;
}

.filter-date-wrapper {
    position: relative;
    padding: 0px 6px 30px 6px;
    min-width: 300px;
    .cal-icon {
        position: absolute;
        top: 8px;
        left: 16px;
        color: $solid-gray;
        @include text-rem(16);
        pointer-events: none;
    }
    .filter-date-prev {
        position: absolute;
        top: 0px;
        padding-top: 8px;
        height: 35px;
        width: 21px;
        text-align: center;
        left: 34px;
        color: $solid-gray;
        @include text-rem(18);
        border-right: 1px solid $default-border-color;
    }
    .filter-date-next {
        position: absolute;
        top: 0px;
        padding-top: 8px;
        padding-left: 1px;
        right: 7px;
        height: 35px;
        width: 27px;
        text-align: center;
        color: $solid-gray;
        @include text-rem(18);
        border-left: 1px solid $default-border-color;
    }
    .filter-date {
        padding: 8px 28px 9px 52px;
        text-align: center;
        width: 100%;
    }
}
