.inline-dropdown {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 10;
    @include flex-wrap;
    flex-direction: column;
    .inline-dropdown-current {
        cursor: pointer;
        color: $text-gray;
        @include proxima-nova(600);
        @include text-rem(12);
        margin-bottom: 8px;
        text-align: right;
        i {
            margin-left: 7px;
        }
    }
    ul {
        display: none;
        @include standard-border;
        background: white;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.08);
        text-align: center;
        margin-right: -3px;
        li {
            color: $charcoal;
            @include proxima-nova(600);
            @include text-rem(14);
            padding: 10px 18px 10px 18px;
            border-bottom: 1px solid $default-border-color;
            cursor: pointer;
            &.active {
                background: $gray;
            }
            &:hover {
                background: $gray;
            }
        }
        &.open {
            display: block;
        }
    }
}
.timeline {
    position: relative;
    .time-span {
        padding-bottom: 30px;
        &:last-of-type {
            padding-bottom: 0px;
        }
        h5 {
            color: $text-gray;
            @include proxima-nova(600);
            @include text-rem(12);
            text-transform: uppercase;
            margin: 0px 0px 25px 0px;
        }
        .time-item {
            &:last-of-type {
                .time-item-inner {
                    border-left: 2px solid transparent;
                }
            }
            &.last {
                .time-item-inner {
                    border-left: 2px solid transparent;
                }
            }
            .time-item-details {
                display: none;
                &.message {
                    display: block;
                }
                &.first {
                    display: block;
                }
                .time-item-details-inner {
                    padding: 14px 18px 20px 18px;
                    @include standard-border;
                    background: $light-gray;
                    margin: 12px 0px 0px 0px;
                    .faux-table {
                        table-layout: fixed;
                        .faux-row {
                            .faux-cell {
                                color: $text-gray;
                                padding: 0px 8px 16px 0px;
                                &.bold {
                                    color: $charcoal;
                                }
                            }
                        }
                    }
                    p {
                        color: $charcoal;
                        margin-bottom: 14px;
                        line-height: 1.4;
                        text-align: left;
                    }
                }
            }
            .time-item-inner {
                padding: 0px 0px 25px 32px;
                border-left: 2px solid $gray;
                margin-left: 12px;
                position: relative;
                .time-line {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    position: absolute;
                    top: 0px;
                    left: -13px;
                    text-align: center;
                    i {
                        color: white;
                        @include text-rem(13);
                        margin-top: 5px;
                    }
                    &.internal {
                        background: $primary-gradient;
                    }
                    &.external {
                        background: $dark-gray;
                    }
                }
                .time-info {
                    color: $charcoal;
                    margin-bottom: 10px;
                }
                .time-details {
                    margin-bottom: 0px;
                    .detail-trigger {
                        color: $light-blue;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
