#search-results {
    display: none;
    position: absolute;
    top: 70px;
    right: 25px;
    width: 500px;
    padding: 20px;
    background-color: grey;
    z-index: 6;
    @include proxima-nova(600);

    background-color: $almost-white;
    border: 1px solid $default-border-color;

    div {
        padding: 0.35em 0;
        border-bottom: 1px solid $gray;

        a {
            color: $link-color;
        }

        &:last-child {
            border-bottom: 0;
            margin-bottom: 0.5em;
        }
    }
}
