/* Base Variables */
@import 'base/variables';

/* Load Gridberg */
@import 'vendor/pump/gridberg/scss/gridberg.scss';

/* Primary Values */
$primary: #922f2a !default;
$primary-light: #ab2922 !default;
$primary-color: $primary;
$primary-gradient: linear-gradient(180deg, $primary-light 0%, $primary 100%);
$primary-gradient-reverse: linear-gradient(0deg, $primary-light 0%, $primary 100%);

/* Libraries */
@import '../vendor/font-awesome/scss/font-awesome.scss';
@import '../vendor/pickadate/css/default';
@import '../vendor/pickadate/css/default.date';
@import '../vendor/pickadate/css/default.time';
@import '../vendor/flatpickr/css/flatpickr.min';

/* Base Styles */
@import 'base/mixins';
@import 'base/base-styles';

/* Layout */
@import 'layouts/body-content';
@import 'layouts/content-nav';
@import 'layouts/header';
@import 'layouts/modal';
@import 'layouts/nav';
@import 'layouts/notifications';
@import 'layouts/pagination';
@import 'layouts/search-results';
@import 'layouts/top-bar';

@import 'layouts/basic-page';
@import 'layouts/login';
@import 'layouts/plain-page';

/* Widgets */
@import 'widgets/widgets';
