#toggle-content-nav {
    display: none;
    width: 100%;
    background: $toggle-content-nav-background;
    border-top: 1px solid $toggle-content-nav-border-color;
    border-bottom: 1px solid $toggle-content-nav-border-color;
    color: $toggle-content-nav-copy-color;
    padding: 12px 24px 12px 24px;
    cursor: pointer;
    @include text-rem(14);
    @include breakpoint(medium) {
        display: block;
    }
    i {
        float: right;
        @include text-rem(16);
        margin-top: -2px;
    }
}

#content-nav {
    background: $content-nav-background;
    @include breakpoint(medium) {
        display: none;
        &.display-medium {
            display: block;
        }
    }
    .content-nav-inner {
        width: 217px;
        padding-top: 13px;
        @include breakpoint(medium) {
            padding-top: 0px;
            width: 100%;
            border-bottom: 1px solid $content-nav-medium-border-color;
        }
        ul {
            li {
                a {
                    display: block;
                    text-decoration: none;
                    @include proxima-nova(400);
                    @include text-rem(14);
                    color: $content-nav-copy-color;
                    padding: 12px 24px 12px 24px;
                    @include standard-transition;
                    &:hover, &.active {
                        background: $content-nav-hover-background;
                    }
                }
            }
        }
    }
}
