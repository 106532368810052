#body-content {

    .widget {

        &.gd-dash {
            .widget-inner {
                .grid {
                    div {
                        margin: 0.5rem 0;

                        div {
                            display: grid;
                            grid-template-columns: auto;
                            grid-template-rows: 75% auto;

                            text-align: center;

                            span {
                                font-size: 4em;
                            }

                            a {
                                font-size: 2em;
                            }
                        }

                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .widget-inner {

            .table-builder {

                .faux-row {

                    .faux-cell.order-details-overview {
                        span {
                            font-weight: bold;
                            display: block;
                            margin: 4px 0;

                            &:first-child {
                                margin-top: 0;
                            }
                        }

                        ul {
                            list-style-type: disc;
                            list-style-position: outside;

                            li {
                                margin-left: 1.3rem;
                                padding: 2px 0;
                            }
                        }
                    }

                    .faux-cell.order-details {
                        > div.service-location {
                            margin-bottom: 1.75em;

                            > table {
                                width: 100%;
                                margin-left: 1em;

                                td {
                                    vertical-align: top;
                                    width: 50%;
                                }
                            }

                            > div {
                                margin-left: 1em;
                            }

                            div.header {
                                font-weight: bold;
                                margin: 0.5em 0;
                            }

                            > div.header {
                                font-size: 1.3em;
                                font-weight: bold;
                                display: block;
                                margin-bottom: 0.3em;
                                margin-left: 0;
                                border-bottom: 1px solid $default-border-color;
                            }

                            div.address {
                                line-height: 1.2em;
                                margin-bottom: 0.3em;

                                span {
                                    font-weight: bold;
                                    display: block;
                                    margin-bottom: 0.3em;
                                }
                            }

                            div.order-details {
                                div > span {
                                    display: block;
                                    margin: 0.5em 0 0.5em 0.5em;
                                }

                                div > div.header {
                                    font-size: 1.2em;
                                }

                                div > table {
                                    width: 100%;

                                    tr:nth-child(odd) {
                                        background-color: $trans-gray;
                                    }

                                    tr {
                                        td {
                                            padding: 0.25em;

                                            &:nth-child(2) {
                                                text-align: right;
                                            }
                                        }

                                    }
                                }
                            }

                            table.units {
                                margin-bottom: 0.3em;

                                td {
                                    padding-right: 1em;

                                    &:last-child {
                                        padding-right: 0;
                                    }
                                }
                            }
                        }

                        div.table {
                            display: table;
                            table-layout: fixed;
                            border-collapse: collapse;

                            > div {
                               display: table-row;

                               > div {
                                    display: table-cell;
                                    vertical-align: middle;
                                    padding: 0.4em;

                                    &:first-child {
                                        padding-left: 0;
                                        text-align: right;
                                        color: $table-subtext-gray;
                                    }

                                    &.bold {
                                        font-weight: bold;
                                    }

                                    &.money {
                                        @include droid;
                                        text-align: right;
                                    }
                                    &.numeric {
                                        @include droid;
                                        text-align: right;
                                        &.left {
                                            text-align: left;
                                        }
                                   }
                               }
                            }
                        }
                    }

                    .faux-cell.log-details {
                        td {
                            padding: 4px 0;
                        }

                        tr {
                            border-top: 1px solid $default-border-color;

                            &:first-child {
                                border-top: 0px;
                            }
                        }

                        tr.property-changes {
                            vertical-align: top;

                            table {
                                tr > td:first-child {
                                    padding-right: 3px;
                                }

                                tr:nth-child(odd) {
                                    background-color: transparentize($primary-light, 0.6);
                                }
                            }
                        }

                        table td {
                            vertical-align: top;
                        }


                    }
                }
            }

            div.multi-select {
                > div {
                    width: calc(50% - 4px);
                    display: inline-flex;
                    vertical-align: top;

                    div.select {
                        flex-grow: 2;
                        margin-right: 8px;
                    }

                    &.selected-options {
                        flex-direction: column;
                        > div {
                            display: flex;
                            margin-bottom: 8px;

                            div {
                                flex-grow: 2;
                                margin-right: 8px;

                                display: flex;
                                align-items: center;
                            }
                        }
                    }
                }
            }
        }
    }

    // Manager Create Order styles

    div#service_location_template {
        display: none;
    }

    div.service_location_group {
        padding-top: 1rem;
        background-color: #fafafa;
        border: 1px solid #dfe2e5;
        border-radius: 0.5rem;
        margin-bottom: 1.5rem;
    }
}
