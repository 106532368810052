.widget.table {
    form {
        width: 100%;
    }
}

.table {
    .faux-row {
        &.tally {
            .faux-cell {
                border-top: 1px solid $off-white;
                padding: 22px 0px 22px 0px;
                color: $charcoal;
                @include text-rem(18);
                margin-bottom: 20px;
                @include proxima-nova(400);
            }
        }
        .faux-cell {
            @include proxima-nova(400);
            @include text-rem(14);
            color: black;
            padding: 0px 0px 22px 0px;
            &.numeric {
                @include droid;
                text-align: right;
            }
        }
    }
}
.table-title {
    display: table;
    width: 100%;
    .table-title-row {
        display: table-row;
        width: 100%;
        > div[class*="-cell"] {
            display: table-cell;
            &.actions {
                min-width: 33.33%;
                text-align: right;
                text-transform: uppercase;
                font-weight: 600;
                font-size: 0.75rem;
                .button {
                    margin: 0px 0px 0px 10px;
                    border-radius: 50%;
                    border: 0px;
                    width: 32px;
                    padding: 9px 0px 0px 0px;
                    height: 32px;
                    text-align: center;
                    display: inline-block;
                }
            }
        }

        .search-cell {
            .search-box {
                position: relative;
                display: flex;
                justify-content: flex-end;

                @include breakpoint(large) {
                    max-width: 220px;
                }
                @include breakpoint(medium) {
                    margin-top: 10px;
                }
                > i {
                    position: absolute;
                    top: 12px;
                    left: 15px;
                    color: $medium-gray;
                }
                input[name=query] {
                    padding: 10px 10px 10px 35px;

                    margin-right: 12px;

                    @include text-rem(14);
                    @include standard-border;
                    @include proxima-nova(500);
                    display: inline-block;
                }
                a.button.filters-active {
                    border-color: $border-green;
                    color: scale-color($border-green, $lightness: -30%);

                    background: $green-gradient;

                    &:hover {
                        background: $green-gradient-reverse;
                    }
                }
                *:last-child {
                    margin-right: 0px;
                }
            }
        }

        .filter-modal {
            div.filter-buttons {
                display: flex;
                width: 100%;
            }
        }
    }

    .table-action-dropdowns {
        display: table-row;
        width: 100%;

        .select {
            display: inline-block;
            margin: 0px 0px 10px 10px;

            select {
                padding: 4px 36px 5px 8px;
            }

            i {
                top: 7px;
                right: 8px;
            }

            &:first-child {
                margin-left: 0px;
            }
        }
    }
}
.faux-table {
    &.compact > .faux-row:last-child > .faux-cell {
        border-bottom: 0;
        padding-bottom: 0;
    }
}
.table-builder {
    position: relative;
    .faux-caption {
        caption-side: bottom;
        display: table-caption;
        @include proxima-nova(700);
        @include text-rem(14);
        border-bottom: 1px solid $default-border-color;
        padding: 10px 10px 10px 10px;
        vertical-align: top;
        text-align: center;
        background: $light-gray;
        color: $table-header-gray;
        text-transform: uppercase;
    }
    .faux-inline-item {
        display: none;
        width: 100%;
        &.open {
            display: table-row;
        }
        .widget {
            padding: 0px 0px 0px 0px;
            margin: 0px;
            .widget-inner {
                .faux-table {
                    .faux-row {
                        background: $almost-white;
                        .faux-cell {
                            &.text {
                                color: #7f8fa4;
                            }
                            &.actions {
                                .actions-dropdown {
                                    max-width: 160px;
                                }
                            }

                        }
                    }
                }
            }
        }
    }
    .faux-row, .faux-inline-item {
        background: white;

        /* Row Styles */
        // New styles added here should also be added to the pump\spadmin\widgets\table\Row::CLASSES constant
        &.disabled {
            text-decoration: line-through;
            color: $text-gray;
        }
        &.hidden {
            display: none;
        }
        &.layout {
            background: none;
            border: none;
            .faux-cell {
                background: none;
                border: none;
            }
        }
        &.gray {
            background-color: $disabled;
        }

        &.border-top {
            .faux-cell {
                border-bottom: 0px;
                border-top: 1px solid $default-border-color;
            }
        }

        &.border-top-bottom {
            .faux-cell {
                border-bottom: 0px;
                border-top: 1px solid $default-border-color;
                border-bottom: 1px solid $default-border-color;
            }
        }

        &.border-none {
            .faux-cell {
                border: 0px;
            }
        }

        &.header {
            .faux-cell {
                text-transform: uppercase;
                color: $table-header-gray;
                @include proxima-nova(600);
                @include text-rem(12);
                border-top: 1px solid $default-border-color;
            }
            .sortable {
                cursor: pointer;
            }
            .active-sort {
                background-color: $default-border-color;
            }
        }
        &.section-header {
            .faux-cell {
                text-transform: uppercase;
                @include proxima-nova(600);
                @include text-rem(12);
                background-color: $default-border-color;
                border: 0;
                padding: 14px;

                &.action_buttons {
                    padding: 4px;
                    > div.action-buttons-wrapper {
                        min-width: 33.33%;
                        text-align: right;
                        text-transform: uppercase;
                        font-weight: 600;
                        font-size: 0.75rem;
                        .button {
                            margin: 0px 0px 0px 10px;
                            border-radius: 50%;
                            border: 0px;
                            width: 32px;
                            padding: 9px 0px 0px 0px;
                            height: 32px;
                            text-align: center;
                            display: inline-block;

                            &.not-allowed {
                                cursor: not-allowed;
                            }
                        }

                    }
                }
            }
        }
        &.list {
            ul {
                li {
                    padding: 0px 0px 12px 0px;
                    &:last-of-type {
                        padding: 0px 0px 0px 0px;
                    }
                }
            }
            ul.disc {
                list-style-type: disc;
                list-style-position: outside;

                li {
                    margin-left: 1.3rem;
                    padding: 2px 0;
                }
            }
        }

        /* Cell Styles */
        .faux-cell {
            @include proxima-nova(400);
            @include text-rem(14);
            border-bottom: 1px solid $default-border-color;
            padding: 10px 10px 10px 10px;
            vertical-align: middle;
            &.align-top {
                vertical-align: top;
            }
            &.text {
                span {
                    display: block;
                    color: $table-subtext-gray;
                    margin-top: 5px;
                }
            }
            &.link {
                span {
                    display: block;
                    color: $table-subtext-gray;
                    margin-top: 5px;
                }
                &.right {
                    text-align: right;
                }
            }
            &.actions {
                position: relative;
                .actions-dropdown {
                    margin: 0px 0px 0px auto;
                    max-width: 220px;
                }
            }
            &.money {
                @include droid;
            }
            &.numeric {
                @include droid;
                text-align: right;
                &.left {
                    text-align: left;
                }
            }
            &.title {
                padding: 0;
                border: 0;
            }
            &.textarea {
                .faux-textarea {
                    white-space: pre-wrap;
                    text-align: left;
                    @include text-rem(14);
                    color: $charcoal;
                    line-height: 1.4;
                    p {
                        color: $charcoal;
                    }
                }
            }
            &.timer {
                span {
                    display: block;
                    color: $table-subtext-gray;
                    margin-top: 5px;
                }
            }
            &.primary {
                color: $primary;
                a {
                    color: $primary;
                }
            }
            &.sort {
                cursor: pointer;
                @include standard-transition;
                &:hover {
                    color: $charcoal;
                }
                i.fa-sort {
                    margin-left: 3px;
                }
            }
            &.key-value {
                div.key-value-table {
                    margin-bottom: 0px;

                    > div > div {
                        display: table-cell;
                        vertical-align: top;
                        padding: 0.25rem;
                        padding-left: 0.75rem;

                        &:first-child {
                            font-weight: normal;
                            color: $table-subtext-gray;;
                            padding-left: 0.25rem;
                        }
                    }
                }
            }
            &.list {
                ul {
                    li {
                        padding: 0px 0px 12px 0px;
                        &:last-of-type {
                            padding: 0px 0px 0px 0px;
                        }
                    }
                }
                ul.disc {
                    list-style-type: disc;
                    list-style-position: outside;

                    li {
                        margin-left: 1.3rem;
                        padding: 2px 0;
                    }
                }
            }
            &.action-selector {
                label.checkbox {
                    margin: 0;

                    .checkmark {
                        margin: 0;
                    }
                }
            }
            .sort-info {
                background: $table-header-gray;
                color: white;
                @include text-rem(10);
                border-radius: 4px;
                text-align: center;
                padding: 2px 4px 2px 4px;
                display: inline-block;
                margin: 0px 0px 0px 5px;
                i {
                    color: white;
                    @include text-rem(10);
                }
            }
            .inline-details {
                color: $light-blue;
                cursor: pointer;
            }
            a {
                cursor: pointer;
                @include proxima-nova(600);
                &.button {
                    display: inline-block;
                    &.not-allowed {
                        cursor: not-allowed;
                    }
                    &:last-of-type {
                        margin-right: 0px;
                    }
               }
            }
        }
    }
}
