.action-header {
    @include flex-wrap;
    h2 {
        color: black;
        @include text-rem(24);
        @include proxima-nova(600);
        margin-bottom: 8px;
    }
    h3 {
        color: $solid-gray;
    }
    p {
        margin-bottom: 10px;
        .bold {
            color: black;
        }
    }
    > .left {
        width: 60%;
        padding-right: 20px;
        @include breakpoint(medium) {
            width: 100%;
            padding-right: 0px;
        }
    }
    > .right {
        width: 40%;
        @include breakpoint(medium) {
            width: 100%;
            margin-bottom: 20px;
        }
    }
}
