/* Mixins */
/* Clear the parent of floated objects like columns (to get the correct height) */
@mixin clearfix {
   &:after{
       content: " ";
       visibility: hidden;
       display: block;
       height: 0;
       clear: both;
   }
}
/* Makes an image responsive, consider adding max-width to the image as well. */
@mixin img-responsive {
    display: block;
    width: 100%;
    height: auto;
}
/* Converts px font-size to %, 16(px) == 100% */
@mixin text-percent($value) {
  font-size: $value / 16 * 100%;
}
/* Converts px font-size to rem */
@mixin text-rem($value) {
    font-size: ($value / 16)+rem;
}
/* place on an inner div center and set a max width matching your 1st break point */
@mixin inner($breakpoint) {
    width: 100%;
    max-width: $breakpoint;
    margin: 0px auto;
}
@mixin inner($break) {
    width: 100%;
    max-width: $break+px;
    margin: 0px auto;
}
/* Flexbox Mixins */
@mixin flex {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}
@mixin flex-wrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    flex-wrap: -webkit-wrap;
}
@mixin margin-center {
    margin: 0px auto;
}
/* build breakpoints from config */
/* Example of how to use:
    @include breakpoint(large) {
        width: 100%;
    }
*/
@mixin breakpoint($break, $rule: max-width) {
    @media handheld, only screen and (#{$rule}: map-get($breakpoints, $break)) {
        @content;
    }
}
