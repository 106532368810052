#login {
    @include flex-wrap;
    width: 100%;
    min-height: 100vh;
    display: inline-flex;
    align-items: center;
    margin: 0px auto;
    padding: 22px 22px 22px 22px;
    .login-inner {
        width: 100%;
        max-width: 300px;
        margin: auto auto auto auto;
        position: relative;
        a.logo {
            display: block;
            width: 100%;
            margin: 0px 0px 36px 0px;
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}
