ul.pagination {
    display: block;
    margin: 0 auto;
    padding: 20px 20px 20px 20px;
    background: $light-gray;
    border-radius: 54px;
    border: 1px solid $default-border-color;
    @include flex;
    li {
        padding: 0px 8px 0px 8px;
        &.active {
            a {
                color: $charcoal;
            }
        }
        a {
            @include proxima-nova(600);
            color: $baby-blue;
            @include text-rem(14);
        }
    }
}
