#wrapper.nav-hidden #nav{
    @include breakpoint(large) {
        transform: translate(-252px, 0);
    }
    #chat {
        @include breakpoint(large) {
            left: 276px;
            &:after {
                left: 11px;
            }
        }
    }
}
#nav {
    background-color: $nav-background-color;
    height: 100vh;
    width: 252px;
    transition: transform 0.35s;
    @include flex;
    position: fixed;
    z-index: 99;
    .nav-inner {
        width: 252px;
        @include flex;
        flex-direction: column;
        .logo {
            display: block;
            width: 100%;
            padding: 23px 20px 24px 20px;
            margin: 0px auto 0px auto;
            background: $nav-logo-background;
            img {
                max-width: 208px;
                @include margin-center;
            }
        }
        .primary-nav {
            width: 100%;
            flex-grow: 1;
            padding-top: 10px;
            @include proxima-nova(600);
            li {
                a {
                    color: $nav-link-color;
                    padding: 10px 14px 11px 32px;
                    display: block;
                    text-decoration: none;
                    cursor: pointer;
                    @include standard-transition;
                    i {
                        margin-right: 16px;
                        @include text-rem(16);
                        &.notification-badge {
                            display: none;
                            float: right;
                            @include text-rem(24);
                            position: relative;
                            color: white;
                            margin-top: -3px;
                            margin-right: 0px;
                            span {
                                position: absolute;
                                left: 0.5px;
                                top: 7px;
                                color: $nav-link-notification-background-color;
                                @include proxima-nova(600);
                                @include text-rem(11);
                                width: 20px;
                                text-align: center;
                                font-style: normal;
                            }
                        }
                    }
                    &:hover, &.active {
                        color: white;
                        background: $nav-link-active-color;
                    }
                }
            }
        }
        #bottom-bar {
            width: 100%;
            align-self: flex-end;
            border-top: 1px solid $nav-bottom-separator-color;
            padding: 0px;
            cursor: pointer;
            @include standard-transition;
            position: relative;
            &.open {
                .fa-angle-up {
                    transform: rotate(180deg);
                }
            }
            #bottom-bar-inner {
                @include flex;
                width: 100%;
                padding: 20px 20px 20px 20px;
                align-items: center;
                .fa-user-circle-o {
                    @include text-rem(24);
                    color: $nav-bottom-user-icon-color;
                    @include standard-transition;
                }
                .user-image {
                    width: 36px;
                    height: 36px;
                    background-color: $off-white;
                    background-image: url('../images/user-image-fallback.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    margin: 0px 19px 0px 0px;
                    border-radius: 3px;
                    position: relative;
                    #status-light {
                        position: absolute;
                        width: 17px;
                        height: 17px;
                        border-radius: 50%;
                        border: 2px solid $nav-background-color;
                        background: black;
                        bottom: -5px;
                        right: -5px;
                        &.available {
                            background: radial-gradient(50% 50% at 50% 50%, #DBFFDC 48.44%, #85FF89 100%);
                        }
                    }
                }
                .user {
                    padding: 0px 0px 0px 0px;
                    .username {
                        @include username;
                        color: $nav-bottom-username-color;
                        margin-bottom: 3px;
                        @include standard-transition;
                    }
                    .role {
                        color: $nav-bottom-user-role-color;
                        margin-bottom: 0px;
                    }
                }
                .fa-angle-up {
                    transition: transform .15s ease-in-out;
                    margin: 0px 0px 0px auto;
                    @include text-rem(18);
                    color: $nav-bottom-expand-color;
                }
            }
            .bottom-bar-nav {
                display: none;
                z-index: 3;
                li {
                    list-style-type: none;
                    i {
                        margin: 0px 19px 0px 0px;
                    }
                    a, span {
                        display: block;
                        padding: 12px 24px 12px 38px;
                        color: black;
                        text-align: left;
                        @include standard-transition;
                        @include text-rem(14);
                        @include proxima-nova(600);
                        color: $nav-bottom-item-color;
                        width: 100%;
                        text-decoration: none;
                        &:hover {
                            background: $nav-bottom-item-color-hover;
                        }
                    }
                    button {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        border: 0px;
                        display: block;
                        padding: 12px 24px 12px 38px;
                        background: none;
                        @include standard-transition;
                        @include text-rem(14);
                        @include proxima-nova(600);
                        color: $nav-bottom-item-color;
                        cursor: pointer;
                        text-align: left;
                        outline: none;
                        width: 100%;
                        &:hover {
                            background: $nav-bottom-item-color-hover;
                        }
                    }
                }
            }
        }
    }

    /* Chat styling has not yet been migrated to the new variable configuration in SPAdmin > 1.8.137 */
    .chat-toggle {
        background: $primary-gradient;
        position: absolute;
        bottom: 10px;
        right: -70px;
        height: 60px;
        width: 60px;
        border-radius: 50%;
        text-align: center;
        cursor: pointer;
        transition: all 0.35s;
        &:hover {
            background: $primary-gradient-reverse;
        }
        &.opened {
            i.fa-comment {
                opacity: 0;
                @include text-rem(0);
                top: 32px;
                left: 32px;
                transform: rotate(90deg);
            }
            i.fa-times {
                opacity: 1;
                @include text-rem(26);
                top: 16px;
                left: 20px;
                transform: rotate(0deg);
            }
        }
        i.fa-comment {
            opacity: 1;
            transition: transform 0.25s, opacity 0.35s;
            @include text-rem(26);
            color: white;
            display: block;
            position: absolute;
            top: 16px;
            left: 17px;
        }
        i.fa-times {
            opacity: 0;
            transition: transform 0.25s, opacity 0.35s;
            transform: rotate(-90deg);
            @include text-rem(0);
            display: block;
            position: absolute;
            top: 32px;
            left: 32px;
            color: white;
        }
        i {
            span {
                background: $cherry;
                color: white;
                @include proxima-nova(600);
                @include text-rem(12);
                border-radius: 4px;
                padding: 6px 8px 6px 8px;
                text-align: center;
                top: -21px;
                right: -21px;
                position: absolute;
                box-shadow: rgba(0, 0, 0, 0.20) 2px 2px 1px 0px, $rose 0px 2px 0px 0px inset;
            }
        }
    }
    #chat {
        display: none;
        background: white;
        position: absolute;
        width: 360px;
        bottom: 84px;
        left: 24px;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.35);
        border-radius: 4px 4px 4px 4px;
        @include modal-bottom-tip(263px, $blue-gray, 0px solid $trans-white);
        z-index: 2;
        &:after {
            box-shadow: 2px 2px 3px rgba(0,0,0,0.25);
        }
        &.opened {
            display: block;
        }
        .chat-inner {
            width: 100%;
            background: white;
            overflow: auto;
            border-radius: 4px 4px 0px 0px;
            height: 300px;
            height: calc(100vh - 496px);
            padding-bottom: 24px;
            @media handheld, only screen and (max-height: 600px){
                height: calc(100vh - 240px);
            }
            .chat-item {
                padding: 16px 24px 12px 24px;
                transition: background 0.35s;
                &.new {
                    background-color: $mint;
                }
                &:first-of-type {
                    padding: 24px 24px 12px 24px;
                }
                .username {
                    @include username;
                    margin-bottom: 6px;
                    color: $slate-blue;
                    .role {
                        @include proxima-nova(400);
                        text-transform: none;
                    }
                }
                .message {
                    color: $dark-gray;
                    line-height: 1.3;
                    margin-bottom: 7px;
                    a {
                        color: $bright-red;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
                .message-datetime {
                    color: $medium-gray;
                    @include text-rem(11);
                    text-transform: uppercase;
                    margin-bottom: 6px;
                }
            }
        }
        .chat-input {
            width: 100%;
            background: $blue-gray;
            padding: 18px 18px 18px 18px;
            border-radius: 0px 0px 4px 4px;
            input {
                width: 100%;
                @include standard-border;
                @include text-rem(14);
                padding: 11px 11px 11px 11px;
            }
        }
    }
}
