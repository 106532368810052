/* Used on smallest responsive layout */
#top-bar {
    display: none;
    border-bottom: 1px solid $top-bar-border-color;
    background: $top-bar-background;
    @include breakpoint(large) {
        display: block;
    }
    .top-bar-inner {
        .toggle-nav {
            color: $top-bar-button-color;
            @include text-rem(20);
            padding: 12px 24px 12px 24px;
            cursor: pointer;
            border-right: 1px solid $top-bar-button-border-color;
            @include standard-transition;
            &:hover {
                background: white;
            }
        }
    }
}
