@mixin button-styles {
    // Ensure styles added here are also added to the /interfaces/ButtonStyles.php constants
    &.primary {
        background: $button-primary-background;
        color: white;
        &:hover {
            background: $primary-gradient-reverse;
        }
    }
    &.light-gray {
        background: $tag-gray;
        color: white;
        &:hover {
            background: $tag-gray;
        }
    }
    &.red {
        background: $bright-red;
        color: white;
        &:hover {
            background: $bright-red;
        }
    }
    &.orange {
        background: $blood-orange;
        color: white;
        &:hover {
            background: $blood-orange;
        }
    }
    &.gold {
        background: $gold;
        color: white;
        &:hover {
            background: $gold;
        }
    }
    &.green {
        background: $green;
        color: white;
        &:hover {
            background: $green;
        }
    }
    &.create {
        background: $button-create-background-color;
        color: white;
        &:hover {
            background: $button-create-background-color;
        }
    }

    &.right {
        margin-left: auto;
        margin-right: 0px;
    }
    &.full-width {
        width: 100%;
        text-align: center;
        margin-right: 0px;
    }
    &.flex-end {
        margin-bottom: 0px;
        align-self: flex-end;
    }
    &.not-allowed {
        cursor: not-allowed;
    }
}

@mixin copy-styles {
    font-size: 1.1rem;
    line-height: 1.5rem;

    h1, h2, h3, h4, h5 {
        margin: 1rem 0 1rem 0;
    }

    h1 {
        font-size: 1.75rem;
        margin-bottom: 1.5rem;
    }
    h2 {
        font-size: 1.5rem;
    }
    h3 {
        font-size: 1.25rem;
    }

    p {
        margin: 0 0 1rem 0;
    }

    table {
        width: 100%;
        margin-bottom: 1rem;

        td {
            padding: 0.5rem;
            border: 1px solid #000;
        }
    }

    ul, ol {
        list-style: initial;
        li {
            margin: 0 0 0.5rem 2rem;
        }
    }

    &.document {
        width: calc(800px + 3rem);;
        max-width: calc(800px + 3rem);
        padding: 0 1.5rem;

        &.padded {
            margin: 0 auto;
        }
    }
}

@mixin droid {
    font-family: "droid-sans-mono",monospace;
    font-style: normal;
    font-weight: 400;
}

@mixin modal-bottom-tip($left, $background, $border) {
    &:after {
        content: " ";
        position: absolute;
        bottom: -5px;
        left: $left;
        background: $background;
        width: 10px;
        height: 10px;
        transform: rotate(45deg);
        border-bottom: $border;
        border-right: $border;
    }
}

@mixin proxima-nova($weight) {
    font-family: "proxima-nova",sans-serif;
    font-style: normal;
    font-weight: $weight;
}

@mixin standard-transition {
    transition: color 0.35s, background 0.35s;
}

@mixin standard-border {
    border: 1px solid #dfe2e5;
    border-radius: 4px;
}

@mixin username {
    text-transform: uppercase;
    @include proxima-nova(600);
}
