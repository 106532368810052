#notifications {
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: 99999;
    .notification {
        width: 280px;
        display: none;
        opacity: 0;
        .notification-inner {
            margin: 15px 15px 0px 0px;
            padding: 22px;
            background-image: linear-gradient(to top, #e2e4e7 0%, #ffffff 100%);
            @include proxima-nova(600);
            @include text-rem(14);
            color: $primary-bold;
            box-shadow: 0 1px 5px rgba(0,0,0,0.35);
            position: relative;
            word-wrap: break-word;
            border-radius: 4px;
            a {
                color: $link-color;
                text-decoration: underline;
            }
            &.success {
                border-bottom: 4px solid $border-green;
            }
            &.fail {
                border-bottom: 4px solid $primary-color;
                animation: shake 0.70s cubic-bezier(.36,.07,.19,.97) both;
                transform: translate3d(0, 0, 0);
                backface-visibility: hidden;
            }
            &.error {
                border-bottom: 4px solid $bright-red;
                animation: shake 0.70s cubic-bezier(.36,.07,.19,.97) both;
                transform: translate3d(0, 0, 0);
                backface-visibility: hidden;
            }
            .close {
                display: block;
                cursor: pointer;
                position: absolute;
                top: 7px;
                right: 10px;
            }

            ul {
                list-style-type: disc;
                list-style-position: outside;

                margin: 1em 0 0 0.75em;

                li {
                    margin: 0.5em 0;
                    line-height: 1.25em;
                }
            }
        }
    }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
