#header {
    width: 100%;
    background: $header-background;
    padding: 24px 24px 24px 24px;
    @include flex;
    justify-content: space-between;
    min-height: 87px;
    @include breakpoint(medium) {
        min-height: 0px;
        display: block;
        padding: 24px 24px 24px 24px;
    }
    h1 {
        @include proxima-nova(400);
        @include text-rem(36);
        color: $header-copy-color;
        padding-right: 24px;
        @include breakpoint(large) {
            @include text-rem(28);
            margin-top: 6px;
        }
        @include breakpoint(medium) {
            @include text-rem(24);
            margin-top: 0px;
            padding-right: 0px;
        }
    }
    .search-box-container {
        width: 100%;
        max-width: 370px;
        .search-box {
            position: relative;
            @include breakpoint(large) {
                max-width: 220px;
            }
            @include breakpoint(medium) {
                margin-top: 10px;
            }
            i {
                position: absolute;
                top: 12px;
                left: 15px;
                color: $medium-gray;
            }
            input {
                padding: 10px 10px 10px 35px;
                @include text-rem(14);
                @include standard-border;
                @include proxima-nova(500);
                width: 100%;
            }
        }
    }
}
