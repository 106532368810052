.accordion {
    .accordion-inner {
        .accordion-heading {
            .heading {
                margin: 0px 0px 7px 0px;
            }
            margin: 0px 0px 10px 0px;
            border-bottom: solid 1px $default-border-color;
        }
        .accordion-content {
            @include standard-border;
            border-radius: 8px;
            overflow: hidden;
            .accordion-top {
                background: $light-gray;
                .accordion-top-inner {
                    padding: 20px 18px 20px 18px;
                    @include flex;
                    .left {
                        flex-grow: 1;
                        .title {
                            text-transform: uppercase;
                            color: $copy-color;
                            @include text-rem(11);
                            @include proxima-nova(600);
                            margin: 0px 0px 4px 0px;
                        }
                        .subjects {
                            margin: 0px 0px 0px 0px;
                            span {
                                border-right: 1px solid #dfe2e5;
                                padding: 0px 6px 0px 6px;
                                &:first-of-type {
                                    padding: 0px 6px 0px 0px;
                                }
                                &:last-of-type {
                                    border-right: 0px;
                                }
                            }
                        }
                    }
                    .right {
                        @include flex;
                        align-items: center;
                        .accordion-toggle {
                            cursor: pointer;
                            @include text-rem(20);
                            color: $primary;
                        }
                    }
                }
            }
            .accordion-items {
                border-top: solid 1px $default-border-color;
                &.columns-one {
                    .accordion-item {
                        width: 100%;
                    }
                }
                &.columns-two {
                    .accordion-item {
                        width: 50%;
                    }
                }
                &.columns-three {
                    .accordion-item {
                        width: 33.33%;
                        @include breakpoint(smedium) {
                            width: 50%;
                        }
                    }
                }
                &.columns-four {
                    .accordion-item {
                        width: 25%;
                        @include breakpoint(medium) {
                            width: 33.33%;
                        }
                        @include breakpoint(smedium) {
                            width: 50%;
                        }
                    }
                }
                &.closed {
                    display: none;
                }
                .accordion-items-inner {
                    padding: 20px 9px 5px 9px;
                    @include flex-wrap;
                    .accordion-item {
                        margin: 0px 0px 15px 0px;
                        padding: 0px 9px 0px 9px;
                        &.force-full-width {
                            width: 100%;
                        }
                        &.force-half-width {
                            width: 50%;
                        }
                        &.force-third-width {
                            width: 33.33%;
                        }
                        &.force-quarter-width {
                            width: 25%;
                        }
                        .accordion-item-inner {
                            &.left {
                                text-align: left;
                            }
                            &.center {
                                text-align: center;
                            }
                            &.right {
                                text-align: right;
                            }
                            .accordion-item-label {
                                @include text-rem(11);
                                text-transform: uppercase;
                                color: $text-gray;
                                margin: 0px;
                            }
                            .accordion-item-value {
                                @include text-rem(14);
                                color: $charcoal;
                                margin: 0px;
                            }
                            .accordion-item-actions {
                                margin: 10px 0px 0px 0px;
                            }
                        }
                    }
                }
            }
        }
    }
}
